import { useState } from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import Tooltip from "../../components/Tooltip/Tooltip";
import Origen from "./Origen";
import Fondos from "./Fondos";
import { convertToMoney } from "../../utils/helper/utils";

export default function EditarFondos({ cuenta, elegibles, onCallbackClose }) {

    const [esVisible, setIsVisible] = useState(cuenta.fondos.length > 1);
    const multifondo = cuenta.fondos.length > 1;
    const [traspaso, setTraspaso] = useState({ porcentaje: cuenta.Porcentaje, tipo: cuenta.tipoTraspaso });
    const [fondo1, setFondo1] = useState({ fondo: cuenta.fondos[0]?.nombre });
    const [fondo2, setFondo2] = useState({ fondo: cuenta.fondos[1]?.nombre });
    const [indiceRecaudador, setIndiceRecaudador] = useState(cuenta.fondos[0]?.fondoRecaudador ? 0 : 1);
    const [cantidadFondos, setCantidadFondos] = useState(cuenta.fondos.length > 1 ? 2 : 1);

    const Obligatorias = ["CCICO", "CAI", "CCIAV"];
    const esObligatoria = Obligatorias.includes(cuenta.tipo);

    const fondos = [
        { fondo: "A", descripcion: "Más riesgoso" },
        { fondo: "B", descripcion: "Riesgoso" },
        { fondo: "C", descripcion: "Intermedio" },
        { fondo: "D", descripcion: "Conservador" },
        { fondo: "E", descripcion: "Más conservador" }
    ];

    const handleClose = () => onCallbackClose?.();

    const radiosClick = (e) => {
        var value = parseInt(e.target.value);

        setIsVisible(value === 1 ? false : true);
        setIndiceRecaudador(value === 1 ? 0 : 1)
        setCantidadFondos(value);
    }

    const cambiarFondo = (fondo, index) => {
        if (index === 0) {
            setFondo1({ fondo: fondo });
        } else {
            setFondo2({ fondo: fondo });
        }
    }

    const onChangeRecaudador = (e) => {
        setIndiceRecaudador(parseInt(e.target.value));
    }

    const enviarDatos = () => {
        const fondos = cuenta.fondos;

        // Actualiza el primer fondo si está definido
        if (fondo1.fondo) {
            fondos[0] = { ...fondos[0], nombre: fondo1.fondo, fondoRecaudador: false };

            if (cantidadFondos === 1 && multifondo) {
                fondos[1] = { ...fondos[1], nombre: fondo1.fondo, fondoRecaudador: false };
            }
        }

        // Actualiza el segundo fondo si multifondo y fondo2 están definidos
        if (cantidadFondos === 2 && multifondo && fondo2.fondo) {
            fondos[1] = { ...fondos[1], nombre: fondo2.fondo, fondoRecaudador: false };
        }

        // Actualiza tipoTraspaso y Porcentaje basados en esObligatoria
        cuenta.tipoTraspaso = esObligatoria ? "total" : traspaso.tipo;

        traspaso.porcentaje = traspaso.porcentaje == "" ? 1 : parseInt(traspaso.porcentaje);
        cuenta.Porcentaje = esObligatoria ? 100 : traspaso.porcentaje;

        // Marca el fondo recaudador
        fondos[indiceRecaudador] = { ...fondos[indiceRecaudador], fondoRecaudador: true };

        // Llama a la función de cierre
        handleClose();
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-10 col-sm-11 col-10 text-center">
                    <p><strong>{cuenta.nombreTipo}</strong></p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-11 col-md-10 col-sm-11 col-10">
                    <p>Saldo total: ${convertToMoney(cuenta.saldo)}</p>
                </div>
            </div>
            <div className="row">
                {cuenta.tipo !== "CCICO" && cuenta.tipo !== "CAI" && cuenta.tipo !== "CCIAV" && (
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="row">
                            <Form.Label htmlFor="tipotraspaso" className="col-sm-4 col-form-label">Tipo de Traspaso</Form.Label>
                            <div className="col-sm-8">
                                <Form.Control
                                    as="select"
                                    value={traspaso.tipo}
                                    onChange={(e) => {
                                        if (e.target.value === "total") {
                                            setTraspaso({ ...traspaso, tipo: e.target.value, porcentaje: 100 });
                                        } else {
                                            setTraspaso({ ...traspaso, tipo: e.target.value, porcentaje: 99 });
                                        }
                                    }}>
                                    <option value="total">Traspaso Total</option>
                                    {/*<option value="parcial">Traspaso Parcial</option>*/}
                                </Form.Control>
                            </div>
                        </div>
                        <div className="row">
                            {traspaso.tipo === "parcial" && (
                                <div className="col-12">
                                    <p className="mt-3 text-small text-center">Elige el porcentaje que quieres traspasar a las cuentas de AFP Modelo</p>
                                    <div className="row">
                                        <Form.Label htmlFor="porcentaje" className="col-sm-4 col-form-label">Porcentaje</Form.Label>
                                        <div className="col-sm-8">
                                            <div className="input-group ">
                                                <FormControl
                                                    type="number"
                                                    style={{ width: "60px" }}
                                                    value={traspaso.porcentaje}
                                                    onBlur={(e) => e.target.value = e.target.value || 1}
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        if (/^[0-9\b]*$/.test(value) && value.length < 3) {
                                                            setTraspaso({ ...traspaso, porcentaje: value === "0" ? "1" : value });
                                                        }
                                                    }}
                                                />
                                                <span className="input-group-text" id="basic-addon1">%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            <hr className="my-4" />

            <div className="mt-2 mb-2">
                <span className="text-small text-center">
                    {!esObligatoria
                        ? "Elige el fondo y el porcentaje de tu cuenta, donde deseas que se administren tus ahorros después del cambio."
                        : "Elige el fondo donde deseas que se administren tus ahorros después del cambio."
                    }
                </span>
            </div>

            {multifondo && (
                <div className="row">
                    <div className="col-lg-3">
                        <input
                            name="numerofondo"
                            type="radio"
                            value="1"
                            defaultChecked={!multifondo}
                            disabled={!multifondo}
                            onChange={radiosClick}
                        />{" Un fondo"}
                    </div>
                    <div className="col-lg-4">
                        <input
                            name="numerofondo"
                            type="radio"
                            value="2"
                            defaultChecked={multifondo}
                            onChange={radiosClick}
                        />{" Dos fondos"}
                    </div>
                </div>
            )}

            <div className="mb-4">
                <Origen esVisible="true" fondo={cuenta.fondos[0]?.origen} />
            </div>
            <div className="mb-3">
                <Fondos
                    esVisible={true}
                    data={fondos}
                    seleccionado={fondo1.fondo}
                    elegibles={elegibles}
                    index={0}
                    onCallback={cambiarFondo} />
            </div>

            {esVisible && (
                <div className="row">
                    <div className="col-sm-5">
                        <input type="radio" name="recaudador" value={0} defaultChecked={cuenta.fondos[0]?.fondoRecaudador} onChange={onChangeRecaudador} />{" Fondo recaudador "}
                    </div>
                    <div className="col-sm-1">
                        <Tooltip text="Fondo recaudador: Es el fondo de destino donde llegarán las siguientes cotizaciones." />
                    </div>
                </div>
            )}

            <Origen esVisible={esVisible} fondo={cuenta.fondos[1]?.origen} />
            <Fondos
                esVisible={esVisible}
                data={fondos}
                seleccionado={fondo2.fondo}
                elegibles={elegibles}
                index={1}
                onCallback={cambiarFondo} />

            {esVisible && (
                <div className="row">
                    <div className="col-sm-5">
                        <input type="radio" name="recaudador" value={1} defaultChecked={cuenta.fondos[1]?.fondoRecaudador} onChange={onChangeRecaudador} />{" Fondo recaudador "}
                    </div>
                    <div className="col-sm-1">
                        <Tooltip text="Fondo recaudador: Es el fondo de destino donde llegarán las siguientes cotizaciones." />
                    </div>
                </div>
            )}

            <div className="row" style={{ textAlign: "center", marginTop: "15px" }}>
                {!elegibles["A"] && !elegibles["B"] && (
                    <div className="alert alert-warning" role="alert">
                        Según la normativa vigente, por tu seguridad no cumples con los requisitos de edad para seleccionar un fondo riesgoso. Elige entre los fondos C, D y E.
                    </div>
                )}
                {!elegibles["A"] && elegibles["B"] && (
                    <div className="alert alert-warning" role="alert">
                        Según la normativa vigente, por tu seguridad no cumples con los requisitos de edad para seleccionar el fondo A. Elige entre los fondos B, C, D y E.
                    </div>
                )}
            </div>

            <div className="row">
                <div className="offset-6 col-3">
                    <Button id="B-TW-0007" variant="primary" onClick={enviarDatos}>
                        Guardar
                    </Button>
                </div>
                <div className="col-3">
                    <Button variant="outline-primary" onClick={handleClose}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </div>
    );
}
